import { TokenResDto } from '@sentinel/hooks';
import { cleanUpStaleCookies, logout } from '@vestwell-frontend/helpers';

import axios from 'axios';
import Cookies from 'js-cookie';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect
} from 'react';
import { useLocation } from 'react-use';
import { createStore, StoreApi, useStore } from 'zustand';

type Auth = {
  token: string;
  setToken: (token: TokenResDto | string) => void;
  updateToken: () => void;
};

type AuthProviderProps = {
  children: ReactNode;
};

const AuthContext = createContext<StoreApi<Auth>>(null);

export const useAuth = () => {
  const store = useContext(AuthContext);
  return useStore(store) as Auth;
};

export const AuthProvider: FC<AuthProviderProps> = props => {
  const host = useLocation().host.split('.').slice(-2).join('.');

  const token = Cookies.get('token');
  const tokenExpiration = Cookies.get('tokenExpiration');

  const authConfigStore = createStore<Auth>()(() => ({
    setToken: token => {
      if (!token) {
        cleanUpStaleCookies();
        return;
      }

      const accessToken =
        typeof token !== 'string' && token?.token_type && token?.access_token
          ? `${token.token_type} ${token.access_token}`
          : // password case
            typeof token === 'string'
            ? token
            : null;

      const refreshToken =
        typeof token !== 'string' ? token?.refresh_token : null;

      Cookies.set('token', accessToken, {
        domain: `.${host}`,
        expires: new Date(new Date().getTime() + 30 * 60 * 1000),
        path: '/'
      });
      // Set token expiration to 13 minutes (to be safe, real expiration is 15 minutes)
      Cookies.set(
        'tokenExpiration',
        new Date(new Date().getTime() + 13 * 60 * 1000).toISOString(),
        {
          domain: `.${host}`,
          expires: new Date(new Date().getTime() + 30 * 60 * 1000),
          path: '/'
        }
      );
      Cookies.set('refreshToken', refreshToken, {
        domain: `.${host}`,
        expires: new Date(new Date().getTime() + 30 * 60 * 1000),
        path: '/'
      });
      axios.defaults.headers.common['Authorization'] = accessToken;
    },
    token,
    updateToken: async () => {
      try {
        const result = await axios.post('/auth/api/v1/oidc/token', {
          grant_type: 'refresh_token',
          refresh_token: Cookies.get('refreshToken')
        });

        authConfigStore.getState().setToken(result.data);
      } catch (error) {
        console.error('Failed to refresh token', error);
        await logout(true);
      }
    }
  }));

  useLayoutEffect(() => {
    axios.defaults.headers.common['Authorization'] = token;
  }, [token]);

  useEffect(() => {
    if (!token) {
      // If there's no token, don't start the interval
      return;
    }

    const checkTokenExpiration = () => {
      const expirationTime = new Date(tokenExpiration || null).valueOf();
      const currentTime = new Date().valueOf();

      if (expirationTime < currentTime) {
        authConfigStore.getState().updateToken();
      }
    };

    //checks on page refresh
    checkTokenExpiration();

    const interval = setInterval(() => {
      checkTokenExpiration();
    }, 1000 * 60); // Check every minute

    return () => clearInterval(interval);
  }, [token, tokenExpiration]);

  return (
    <AuthContext.Provider value={authConfigStore}>
      {props.children}
    </AuthContext.Provider>
  );
};
