import { Box, styled, useMediaQuery } from '@mui/material';
import { AccountMenu } from '@vestwell-frontend/elements';
import { Topbar } from '@vestwell-frontend/ui';

import { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-use';

import { routes } from '../router/routes';
import { BackgroundCard } from './BackgroundCard';
import { Banner } from './Banner';
import { Help } from './Help';
import { Page } from './Page';

const HelpContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    alignItems: 'center',
    margin: 'auto'
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}));

export const Root: FC<{
  children?: ReactNode;
  isSpecialPage?: boolean;
}> = props => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const path = useLocation();
  const isSpecialPage =
    [routes.AUTHORIZATION, routes.LOGIN_AND_SECURITY, routes.PERSONAS].includes(
      path.pathname as any
    ) || props.isSpecialPage;
  const isTopBar = path.pathname === routes.PERSONAS;

  return (
    <>
      {isTopBar && (
        <Topbar>
          <Box ml='auto'>
            <AccountMenu />
          </Box>
        </Topbar>
      )}
      {!isSpecialPage && !isMobile && <Banner />}
      <Page>
        {isSpecialPage ? (
          props.children || <Outlet />
        ) : (
          <>
            {isSpecialPage && isMobile && <Banner />}
            <HelpContainer>
              <BackgroundCard>{props.children || <Outlet />}</BackgroundCard>
              <Help />
            </HelpContainer>
          </>
        )}
      </Page>
    </>
  );
};

Root.displayName = 'Root';
