import isPropValid from '@emotion/is-prop-valid';
import {
  Tab as TabMui,
  TabProps as TabMuiProps,
  TabPanel as TabPanelMui,
  TabPanelProps as TabPanelMuiProps,
  TabsList as TabsListMui,
  Tabs as TabsMui
} from '@mui/base';
import { styled, useMediaQuery } from '@mui/material';
import { useCamelCase } from '@vestwell-frontend/hooks';

import cn from 'clsx';
import { get } from 'lodash';
import {
  Children,
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Dropdown } from './Dropdown';

const TabContext = createContext(null);

export const TabPanel: FC<TabPanelMuiProps> = props => {
  const { tabs, index } = useContext(TabContext);

  const title = tabs[index]?.title ?? 'Untitled';

  return (
    <TabPanelMui
      {...props}
      className={props.className}
      data-component='tabPanel'
      data-testid={useCamelCase(title)}
      value={props.value?.toString()}
    />
  );
};

type MobileTabListProps = {
  children?: ReactNode;
  className?: string;
};

const MobileTabList: FC<MobileTabListProps> = props => {
  const { name, setIndex, tabs, index } = useContext(TabContext);

  const dropdownOptions = useMemo(() => {
    return tabs
      .filter(tab => !!tab.title)
      .map(tab => ({ label: tab.title, value: tab.index.toString() }));
  }, [tabs]);

  return (
    <div className='flex justify-center' role='presentation'>
      <Dropdown
        className={cn('mt-2 mb-4', props.className)}
        fullWidth
        name={name}
        onChange={setIndex}
        options={dropdownOptions}
        value={index.toString()}
      />
    </div>
  );
};

export type TabListProps = {
  className?: string;
  children?: ReactNode;
};

export const TabList: FC<TabListProps> = props => {
  const { variant } = useContext(TabContext);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  if (isMobile || variant === 'mobile') {
    return (
      <MobileTabList className={props.className}>
        {props.children}
      </MobileTabList>
    );
  }

  return (
    <TabsListMui
      className={cn(
        'font-ui',
        {
          'border-b-1 border-grey600': variant === 'large',
          'mb-8': !props.className?.includes('mb-')
        },
        props.className
      )}>
      {props.children}
    </TabsListMui>
  );
};

export type TabProps = TabMuiProps & {
  /** Route path to sync Tabs state with; selects tab on match and navigates on selection */
  route?: string;
  /** Visible tab label */
  title: string;
};

const StyledTab = styled(TabMui, {
  shouldForwardProp: isPropValid
})<TabProps>(({ theme, ...props }) => ({
  ...(props['data-variant'] === 'small'
    ? {
        ...(props['aria-selected']
          ? {
              backgroundColor: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              color: theme.palette.white.main
            }
          : {
              backgroundColor: theme.palette.white.main,
              borderColor: theme.palette.ash.main
            }),
        '&:first-of-type': {
          borderRadius: theme.spacing(1.5, 0, 0, 1.5)
        },
        '&:last-of-type': {
          borderRadius: theme.spacing(0, 1.5, 1.5, 0)
        },
        '&:only-of-type': {
          borderRadius: theme.spacing(1.5)
        },
        borderWidth: theme.spacing(0.25),
        fontSize: theme.spacing(3.5),
        fontWeight: 700,
        minWidth: theme.spacing(24),
        padding: theme.spacing(2, 1)
      }
    : {
        ...(props['aria-selected']
          ? {
              borderTopColor: theme.palette.primary.main,
              color: theme.palette.oxfordBlue.main,
              fontWeight: 700
            }
          : {
              '&:hover': {
                backgroundColor: theme.palette.grey800.main
              },
              borderColor: 'transparent'
            }),
        borderRadius: theme.spacing(0.5, 0.5, 0, 0),
        borderWidth: theme.spacing(0.5, 0.25, 0, 0.25),
        fontSize: theme.spacing(4.5),
        lineHeight: theme.spacing(4),
        minWidth: theme.spacing(16),
        padding: theme.spacing(3.75, 6)
      }),
  ...(props['aria-selected']
    ? {}
    : {
        color: theme.palette.grey50.main
      }),
  '&:focus-visible': {
    outline: `1px solid ${theme.palette.primary.main}`
  }
}));

export const Tab: FC<TabProps> = ({ type = 'button', ...props }) => {
  const { variant, index, setIndex } = useContext(TabContext);

  const isSelected = props.value === index;

  const onClick = useCallback(event => {
    setIndex(+event.target?.dataset?.index);
  }, []);

  return (
    <StyledTab
      aria-selected={isSelected}
      className={props.className}
      data-component='tab'
      data-index={props.value}
      data-route={props.route}
      data-testid={useCamelCase(props.title)}
      data-variant={variant}
      onClick={onClick}
      title={props.title}
      type={type}
      value={props.value?.toString()}>
      {props.title}
    </StyledTab>
  );
};

export type TabsProps = {
  className?: string;
  children: ReactNode;
  /** Initial selected tab for uncontrolled usage */
  defaultIndex?: number;
  /** Control selected tab; must be used together with onChange */
  index?: number;
  /** Disambiguate with a label when multiple sets of tabs are present  */
  name?: string;
  /** (tabIndex: Number) - emits on tab selection */
  onChange?(index: number): void;
  variant?: 'large' | 'small' | 'mobile';
};

export const Tabs: FC<TabsProps> = ({
  name = 'tabs',
  variant = 'large',
  ...props
}) => {
  const tabs = useMemo(
    () =>
      Children.map(
        get(props.children, '0.props.children', []),
        (child, index) => ({
          index,
          route: get(child, 'props.route'),
          title: get(child, 'props.title')
        })
      ),
    [props.children]
  );

  const location = useLocation();
  const navigate = useNavigate();

  const routes = useMemo(() => tabs.map(tab => tab.route), [tabs]);

  const locationIndex = useMemo(
    () => routes.findIndex(route => route === location.pathname),
    [location.pathname, routes]
  );

  const initialIndex =
    props.defaultIndex !== undefined
      ? props.defaultIndex
      : locationIndex !== -1
        ? locationIndex
        : 0;

  // Drive tab selection from state so child components (e.g. mobile variant) can change tab via Context API
  const [index, setIndex] = useState(initialIndex);

  useEffect(() => {
    if (locationIndex === -1 && props.index === undefined) return;
    locationIndex >= 0 ? setIndex(locationIndex) : setIndex(props.index);
  }, [locationIndex, props.index]);

  useEffect(() => {
    const route = tabs[index] && tabs[index].route;

    if (route && route !== location.pathname) {
      navigate(route);
    }

    if (props.onChange) {
      props.onChange(index);
    }

    // exclude location and navigate dependencies; this should only trigger on state updates
  }, [index]);

  const onChange = useCallback(event => {
    setIndex(+event.target?.dataset?.index);
  }, []);

  const ctx = useMemo(
    () => ({
      index,
      name,
      setIndex,
      tabs,
      variant
    }),
    [name, variant, index, tabs]
  );

  return (
    <TabContext.Provider value={ctx}>
      <TabsMui
        aria-label={name}
        className={cn('mb-8', props.className)}
        data-component='Tabs'
        defaultValue={initialIndex}
        onChange={onChange}
        value={index.toString()}>
        {props.children}
      </TabsMui>
    </TabContext.Provider>
  );
};
