import { InfoOutlined } from '@mui/icons-material';
import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormHelperText,
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
  Radio,
  RadioProps,
  styled
} from '@mui/material';
import { useCamelCase } from '@vestwell-frontend/hooks';

import cn from 'clsx';
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useId,
  useMemo,
  useState
} from 'react';
import { useUpdateEffect } from 'react-use';

import { FormFieldLabel, FormFieldLabelProps } from './form/FormFieldLabel';

export type RadioButtonVariant = 'standard' | 'card';

export type RadioGroupProps = Partial<FormFieldLabelProps> &
  Omit<MuiRadioGroupProps, 'onChange'> & {
    error?: FormControlProps['error'];
    errorMessage?: ReactNode;
    formField?: boolean;
    hideError?: boolean;
    label?: FormControlLabelProps['label'];
    fullWidth?: FormControlProps['fullWidth'];
    onChange?: (value: any) => void;
    required?: boolean;
    position?: 'left' | 'right';
    variant?: RadioButtonVariant;
  };

type RadioGroupContextValue = Pick<
  RadioGroupProps,
  | 'disabled'
  | 'fullWidth'
  | 'name'
  | 'onChange'
  | 'required'
  | 'position'
  | 'value'
  | 'variant'
>;

const RadioGroupContext = createContext<RadioGroupContextValue>({
  variant: 'standard'
});

const StyledRadio = styled(Radio, {
  shouldForwardProp: prop => prop !== 'variant'
})<RadioProps & { variant: RadioButtonVariant }>(props => ({
  margin: props.variant === 'card' ? undefined : props.theme.spacing(1, 2)
}));

const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: prop =>
    !['fullWidth', 'position', 'variant'].includes(prop as string)
})<
  FormControlLabelProps &
    Pick<RadioGroupContextValue, 'fullWidth' | 'position' | 'variant'>
>(props => ({
  ...(props.variant === 'card'
    ? {
        '& .MuiFormControlLabel-label': {
          flex: 1
        },
        '& .MuiRadio-root': {
          flex: 0
        },
        alignItems: 'flex-start',
        ...(props.checked
          ? { border: `1px solid ${props.theme.palette.primary.main}` }
          : { border: '1px solid transparent' }),
        borderRadius: '0.437rem',
        boxShadow: props.theme.shadows[props.theme.shadows.length - 1],
        columnGap: props.theme.spacing(3),
        display: 'flex',
        flexDirection: props.position === 'left' ? 'row' : 'row-reverse',
        justifyContent: props.position === 'left' ? undefined : 'space-between',
        marginBottom: props.theme.spacing(4),
        marginRight: 0,
        maxWidth: '100%',
        padding: props.theme.spacing(3),
        ...(props.fullWidth
          ? { width: '100%' }
          : {
              // todo:: old rule; should be replaced with width: 100%
              minWidth: '18.75rem'
            })
      }
    : {})
}));

export type RadioButtonProps = Omit<FormControlLabelProps, 'control'>;

export const RadioButton: FC<RadioButtonProps> = ({
  className,
  label,
  ...props
}) => {
  const ctx = useContext(RadioGroupContext);

  const onChange = useCallback(() => {
    if (!props.disabled) {
      ctx.onChange(props.value);
    }
  }, [ctx.onChange, props.disabled, props.value]);

  return (
    <StyledFormControlLabel
      {...props}
      checked={ctx.value === props.value}
      className={className}
      control={
        <StyledRadio
          inputProps={{
            'aria-checked': ctx.value === props.value,
            'aria-disabled': !!ctx.disabled,
            'aria-required': !!ctx.required,
            //@ts-expect-error
            'data-component': 'radioButtonInput',
            'data-testid': `${ctx.name}_${useCamelCase(
              props.value?.toString()
            )}`
          }}
          onChange={onChange}
          size={ctx.variant === 'card' ? 'medium' : 'small'}
          variant={ctx.variant}
        />
      }
      data-component='radioButtonLabel'
      data-testid={`${ctx.name}_${useCamelCase(props.value?.toString())}`}
      disableTypography
      disabled={props.disabled || !!ctx.disabled}
      fullWidth={ctx.fullWidth}
      label={label}
      position={ctx.position}
      variant={ctx.variant}
    />
  );
};

RadioButton.displayName = 'RadioButton';

export const RadioGroup: FC<RadioGroupProps> = ({
  className,
  details,
  disabled,
  error,
  errorMessage,
  formField,
  fullWidth,
  label,
  helpModal,
  hideLabel,
  hideError,
  info,
  onChange,
  position = 'right',
  value: initialValue = '',
  variant = 'standard',
  ...props
}) => {
  const detailsId = useId();
  const disabledId = useId();
  const helpModalId = useId();
  const errorId = useId();
  const infoId = useId();
  const labelId = useId();

  const [value, setValue] = useState(initialValue);

  const describedBy =
    `${disabled ? `${disabledId} ` : ''}${info ? `${infoId} ` : ''}${
      helpModal ? `${helpModalId} ` : ''
    }${details ? `${detailsId} ` : ''}${
      errorMessage ? `${errorId} ` : ''
    }`.trimEnd() || undefined;

  const onRadioButtonChange = useCallback(
    value => {
      setValue(value);

      if (onChange) {
        onChange(value);
      }
    },
    [onChange]
  );

  const ctx = useMemo(
    () => ({
      disabled: !!disabled,
      fullWidth: !!fullWidth,
      name: props.name,
      onChange: onRadioButtonChange,
      position,
      required: !!props.required,
      value,
      variant
    }),
    [
      disabled,
      fullWidth,
      onRadioButtonChange,
      position,
      value,
      variant,
      props.name,
      props.required
    ]
  );

  useUpdateEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <FormControl
      className={cn('flex', className)}
      data-component='radioGroupControl'
      data-testid={props.name}
      error={error}
      fullWidth={fullWidth}>
      <FormFieldLabel
        data-component='radioGroupLabel'
        details={details}
        detailsId={detailsId}
        disabled={!!disabled}
        disabledId={disabledId}
        helpModal={helpModal}
        helpModalId={helpModalId}
        hideLabel={hideLabel}
        info={info}
        infoId={infoId}
        label={label}
        labelId={labelId}
        name={props.name}
        required={!!props.required}
      />
      <RadioGroupContext.Provider value={ctx}>
        <MuiRadioGroup
          {...props}
          aria-describedby={describedBy}
          aria-disabled={!!disabled}
          aria-invalid={!!error}
          aria-labelledby={labelId}
          aria-required={!!props.required}
          data-component='radioGroup'
          data-testid={props.name}
          value={initialValue}
        />
      </RadioGroupContext.Provider>
      {formField && (
        <FormHelperText
          aria-atomic='true'
          className='mx-0'
          data-component='radioGroupError'
          data-testid={props.name}
          id={errorId}
          role='alert'>
          {!hideError && errorMessage ? (
            <>
              <InfoOutlined className='mr-1' color='error' fontSize='small' />
              {!!label && <span className='sr-only'>Error for {label}:</span>}
              {errorMessage}
            </>
          ) : (
            ' '
          )}
        </FormHelperText>
      )}
    </FormControl>
  );
};

RadioGroup.displayName = 'RadioGroup';
