import { useGetUserinfo, usePostToken } from '@sentinel/hooks';
import { useAuth } from '@vestwell-frontend/elements';
import {
  useDocumentTitle,
  useNavigate,
  useSearchParams
} from '@vestwell-frontend/hooks';

import { useMount, useUpdateEffect } from 'react-use';

import { routes } from '../router/routes';

export function AuthorizationPage() {
  useDocumentTitle('Authorization');

  const params = useSearchParams();

  const { setToken } = useAuth();

  const navigate = useNavigate();

  const postToken = usePostToken({
    mutation: {
      onSuccess: data => {
        setToken(data);
      }
    }
  });
  const userInfo = useGetUserinfo({
    query: {
      enabled: !!postToken.data?.access_token
    }
  });

  useMount(async () => {
    setToken('');
    await postToken.mutateAsync({
      //@ts-expect-error: TODO: fix types
      data: { code: params.code, grant_type: 'authorization_code' }
    });
  });

  useUpdateEffect(() => {
    if (userInfo.data?.isMfaEnabled) {
      navigate(routes.MFA_VERIFICATION);
      return;
    }

    //@ts-expect-error
    if (userInfo.data?.hasMfaNudge) {
      navigate(routes.MFA_NUDGE);
      return;
    }

    if (userInfo.data) {
      navigate(routes.PERSONAS);
    }
  }, [userInfo.data]);

  return null;
}

AuthorizationPage.displayName = 'AuthorizationPage';
