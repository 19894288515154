import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import { Alert, Unstable_Grid2 as Grid } from '@mui/material';
import { useGetQrCode, usePostMfaSetup } from '@sentinel/hooks';
import { useDocumentTitle } from '@vestwell-frontend/hooks';
import {
  Button,
  Link,
  ModalBody,
  ModalFooter,
  Text,
  useWizard
} from '@vestwell-frontend/ui';

import { FC, useCallback } from 'react';
import { useMount } from 'react-use';

import { MfaQrCode } from '../components';
import { MfaNudgeState } from '../config/mfaNudge';

export const MfaNudgeAppSetupPage: FC = () => {
  const ctx = useWizard<MfaNudgeState>();

  useDocumentTitle(ctx.step.title as string);

  const setup = usePostMfaSetup({
    mutation: {
      onSuccess: res => {
        console.log(res.code);
      }
    }
  });

  const qrCode = useGetQrCode({
    query: {
      enabled: setup.isSuccess,
      refetchOnMount: 'always',
      suspense: false
    }
  });

  const onBack = useCallback(async () => {
    ctx.setState({ isMfaMethodSelected: false });
  }, []);

  const onContinue = useCallback(() => {
    ctx.setState({ hasSetupMfaMethod: true });
  }, []);

  useMount(async () => {
    await setup.mutateAsync({
      data: { type: 'app' }
    });
  });

  const isLoading = setup.isLoading || qrCode.isInitialLoading;

  return (
    <>
      <ModalBody>
        <Grid
          alignItems='center'
          columns={12}
          container
          direction='column'
          spacing={4}>
          <Grid
            alignItems='center'
            display='flex'
            flexDirection='column'
            lg={12}>
            <Text
              align='center'
              color='oxfordBlue'
              component='span'
              mb={2}
              variant='f2'>
              Two-Factor Authentication
            </Text>
            <Text align='center' color='oxfordBlue' variant='b2'>
              {ctx.step.title}
            </Text>
          </Grid>
          <Grid
            alignItems='center'
            display='flex'
            flexDirection='column'
            lg={11}
            xs={12}>
            <Text align='center'>
              Download an authenticator app such as{' '}
              <Link
                data-testid='googleAuthenticatorLink'
                external
                to='https://support.google.com/accounts/answer/1066447'>
                Google Authenticator
              </Link>
              , or{' '}
              <Link
                data-testid='microsoftAuthenticatorLink'
                external
                to='https://support.microsoft.com/en-au/account-billing/download-and-install-the-microsoft-authenticator-app-351498fc-850a-45da-b7b6-27e523b8702a'>
                Microsoft Authenticator
              </Link>
              . Once downloaded, scan the following barcode from within the app.
            </Text>
          </Grid>
          <Grid
            alignItems='center'
            display='flex'
            flexDirection='column'
            lg={9}
            xs={12}>
            <MfaQrCode
              isLoading={qrCode.isInitialLoading}
              qrCode={qrCode.data?.qrCode}
              secret={qrCode.data?.secret}
            />
          </Grid>
          {(setup.error || qrCode.error) && (
            <Grid
              alignItems='center'
              display='flex'
              flexDirection='column'
              lg={12}>
              <Alert severity='error' variant='standard'>
                {setup.error?.message || qrCode.error?.message}
              </Alert>
            </Grid>
          )}
        </Grid>
      </ModalBody>
      <ModalFooter justifyBetween noBoxShadow>
        <Button onClick={onBack} variant='text'>
          Back
        </Button>
        <Button
          disabled={isLoading || !!setup.error || !!qrCode.error}
          endIcon={<KeyboardArrowRightOutlined fontSize='small' />}
          onClick={onContinue}>
          Next: Verify
        </Button>
      </ModalFooter>
    </>
  );
};

MfaNudgeAppSetupPage.displayName = 'MfaNudgeAppSetupPage';
