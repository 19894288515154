import Cookies from 'js-cookie';

export const cleanUpStaleCookies = () => {
  const attributes = {
    domain: `.${window.location.host.split('.').slice(-2).join('.')}`,
    path: '/'
  };
  Cookies.remove('isLoginDisclosure', attributes);
  Cookies.remove('token', attributes);
  Cookies.remove('tokenExpiration', attributes);
  Cookies.remove('refreshToken', attributes);
};

export const logout = async (timeout?) => {
  const token = Cookies.get('token');

  cleanUpStaleCookies();

  try {
    await fetch('/auth/api/v2/logout', {
      headers: {
        Authorization: token
      },
      method: 'GET'
    });
  } catch (_) {
    // Do nothing
  }

  window.location.href = `${window.location.origin}${timeout === true ? `?error=TIMEOUT` : ''}`;
};
