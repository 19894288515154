import {
  AdminPanelSettingsOutlined,
  ArrowDropDown,
  GppGoodOutlined,
  Logout,
  LoopOutlined
} from '@mui/icons-material';
import { IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { useGetPersonas } from '@sentinel/hooks';
import { logout } from '@vestwell-frontend/helpers';
import { useSubdomain } from '@vestwell-frontend/hooks';

import Cookies from 'js-cookie';
import { FC, useCallback, useId, useState } from 'react';
import { useLocation } from 'react-use';

export const AccountMenu: FC = () => {
  const menuId = useId();

  const subdomain = useSubdomain();

  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const personas = useGetPersonas(
    {
      disableDetails: true
    },
    {
      query: {
        enabled: location.pathname !== '/personas',
        keepPreviousData: true,
        suspense: true,
        useErrorBoundary: true
      }
    }
  );

  const onClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onLoginAndSecurity = useCallback(() => {
    window.location.pathname = '/login-and-security';
  }, []);

  const onSwitchAccount = useCallback(() => {
    Cookies.remove('isLoginDisclosure', {
      domain: `.${window.location.host.split('.').slice(-2).join('.')}`,
      path: '/'
    });

    window.location.pathname = '/personas';
  }, []);

  return (
    <>
      <IconButton
        aria-controls={open ? menuId : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        aria-label='Open Account Settings'
        data-testid='accountSettings'
        onClick={onClick}
        size='small'
        sx={{
          height: 'fit-content',
          width: 'fit-content'
        }}>
        <AdminPanelSettingsOutlined fontSize='large' />
        <ArrowDropDown />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        hideBackdrop
        id={menuId}
        onClick={onClose}
        onClose={onClose}
        open={open}>
        {subdomain !== 'abaretirement' && (
          <MenuItem onClick={onLoginAndSecurity}>
            <ListItemIcon>
              <GppGoodOutlined sx={{ fontSize: 24 }} />
            </ListItemIcon>
            Login & Security
          </MenuItem>
        )}
        {location.pathname !== '/personas' && personas.data?.count > 1 && (
          <MenuItem onClick={onSwitchAccount}>
            <ListItemIcon>
              <LoopOutlined sx={{ fontSize: 24 }} />
            </ListItemIcon>
            Switch Account
          </MenuItem>
        )}
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout sx={{ fontSize: 24 }} />
          </ListItemIcon>
          Sign out
        </MenuItem>
      </Menu>
    </>
  );
};
