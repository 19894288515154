import { InfoOutlined } from '@mui/icons-material';
import {
  IconButton,
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps
} from '@mui/material';
import { IconButtonProps } from '@mui/material/IconButton/IconButton';

import cn from 'clsx';
import { FC, ReactNode } from 'react';

export type IconTooltipProps = IconButtonProps & {
  'aria-label'?: MuiTooltipProps['aria-label'];
  arrow?: MuiTooltipProps['arrow'];
  className?: string;
  children?: ReactNode;
  id?: string;
  onClick?: MuiTooltipProps['onClick'];
  placement?: MuiTooltipProps['placement'];
  title?: MuiTooltipProps['title'];
  trigger?: ReactNode;
};

export const IconTooltip: FC<IconTooltipProps> = ({
  ['aria-label']: ariaLabel,
  arrow = true,
  className,
  children,
  onClick,
  placement,
  title,
  trigger = <InfoOutlined color='primary' fontSize='small' />,
  ...props
}) => {
  return (
    <MuiTooltip
      aria-label={ariaLabel ?? 'More Info'}
      arrow={arrow}
      componentsProps={{
        popper: {
          // @ts-expect-error - problems with types
          'data-testid': props['data-testid']
        }
      }}
      describeChild
      enterTouchDelay={0}
      onClick={onClick}
      placement={placement}
      title={title || children}>
      <IconButton {...props} className={cn('ml-1', className)} size='small'>
        {trigger}
      </IconButton>
    </MuiTooltip>
  );
};
