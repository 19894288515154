import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import { Alert, Unstable_Grid2 as Grid, styled } from '@mui/material';
import { usePostMfaSetup, usePostMfaSetupVerify } from '@sentinel/hooks';
import { useAuth } from '@vestwell-frontend/elements';
import { useDocumentTitle } from '@vestwell-frontend/hooks';
import {
  Button,
  Form,
  FormatPhoneNumber,
  FormField,
  FormSaveButton,
  ModalBody,
  ModalFooter,
  Text,
  useWizard
} from '@vestwell-frontend/ui';

import { FC, useCallback } from 'react';
import * as yup from 'yup';

import { ResendOtpButton } from '../components';
import { MfaNudgeState } from '../config/mfaNudge';

const StyledFormField = styled(FormField)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  width: theme.spacing(50)
}));

const schema = yup.object().shape({
  securityCode: yup
    .string()
    .min(6, 'Must be at least 6 digits')
    .required('Required')
});

const StyledGrid = styled(Grid)(props => ({
  minHeight: 400,
  [props.theme.breakpoints.down('sm')]: {
    maxHeight: '75vh'
  }
}));

const FlexGrowGrid = styled(Grid)({
  flexGrow: '1 !important' // <Grid /> overrides with flex-grow: 0 on >1200px
});

const StyledResendOtpButton = styled(ResendOtpButton)(({ theme }) => ({
  marginBottom: theme.spacing(4)
}));

export const MfaNudgeVerifyPage: FC = () => {
  const { setToken } = useAuth();

  const ctx = useWizard<MfaNudgeState>();

  useDocumentTitle(ctx.step.title as string);

  const postMfaSetup = usePostMfaSetup({
    mutation: {
      onSuccess: res => {
        console.log(res.code);
      }
    }
  });

  const postMfaSetupVerify = usePostMfaSetupVerify({
    mutation: {
      onSuccess: data => {
        ctx.setState({
          hasSetupMfaVerified: true
        });

        //@ts-expect-error
        setToken(data.token);
      }
    }
  });

  const onBack = useCallback(() => {
    ctx.setState({ hasSetupMfaMethod: false });
  }, []);

  const onResendCode = useCallback(async () => {
    await postMfaSetup.mutateAsync({
      data: {
        type: ctx.state.mfaMethod,
        value: ctx.state.mfaPhone
      }
    });
  }, [ctx.state]);

  const onSubmit = useCallback(
    async values => {
      await postMfaSetupVerify.mutateAsync({
        data: {
          code: values.securityCode,
          isRegistration: true,
          subType: ctx.state.mfaMethodSubType
        }
      });
    },
    [ctx.state]
  );

  const isDisabled = postMfaSetupVerify.error?.message?.startsWith(
    'Your account has been temporarily locked'
  );

  return (
    <Form
      className='contents'
      onChange={postMfaSetupVerify.reset}
      onSubmit={onSubmit}
      validationSchema={schema}>
      <ModalBody>
        <StyledGrid
          alignItems='center'
          columns={12}
          container
          direction='column'
          spacing={4}>
          <Grid
            alignItems='center'
            display='flex'
            flexDirection='column'
            lg={12}>
            <Text
              align='center'
              color='oxfordBlue'
              component='span'
              mb={2}
              variant='f2'>
              Two-Factor Authentication
            </Text>
            <Text align='center' color='oxfordBlue' variant='b2'>
              {ctx.step.title}
            </Text>
          </Grid>
          <Grid
            alignItems='center'
            display='flex'
            flexDirection='column'
            lg={12}>
            <Text align='center' color='black' mb={0}>
              Enter the 6-digit code{' '}
              {ctx.state.mfaMethodSubType === 'text'
                ? 'we have sent to '
                : ctx.state.mfaMethodSubType === 'voice'
                  ? 'provided on the call to '
                  : 'generated by your authentication app'}
              {ctx.state.mfaMethod === 'phone' && (
                <>
                  <FormatPhoneNumber mask value={ctx.state.mfaPhone} />.
                </>
              )}
            </Text>
          </Grid>
          <FlexGrowGrid
            alignItems='center'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            lg={12}>
            <StyledFormField
              align='center'
              autoComplete='one-time-code'
              autoFocus
              disabled={isDisabled}
              format='numericString'
              label='Security Code'
              maxLength={6}
              name='securityCode'
              type='tel'
              variant='large'
            />
            {ctx.state.mfaMethod === 'phone' && (
              <StyledResendOtpButton
                disabled={isDisabled}
                inputName={`${ctx.state.mfaMethod}Verification`}
                onClick={onResendCode}
                variant='inline'
              />
            )}
          </FlexGrowGrid>
          {(postMfaSetup.error || postMfaSetupVerify.error) && (
            <Grid
              alignItems='center'
              display='flex'
              flexDirection='column'
              lg={12}>
              <Alert severity='error' variant='standard'>
                {postMfaSetup.error?.message ??
                  postMfaSetupVerify.error?.message}
              </Alert>
            </Grid>
          )}
        </StyledGrid>
      </ModalBody>
      <ModalFooter justifyBetween noBoxShadow>
        <Button disabled={isDisabled} onClick={onBack} variant='text'>
          Back
        </Button>
        <FormSaveButton
          data-testid='confirm'
          disabled={isDisabled}
          endIcon={<KeyboardArrowRightOutlined fontSize='small' />}>
          Confirm
        </FormSaveButton>
      </ModalFooter>
    </Form>
  );
};

MfaNudgeVerifyPage.displayName = 'MfaNudgeVerifyPage';
