import Cookies from 'js-cookie';
import { MouseEvent, useCallback, useState } from 'react';

import { useNotifications } from './useNotifications';

export const useDownload = (pathname: string) => {
  const [data, setData] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const token = Cookies.get('token');

  const notify = useNotifications();

  return useCallback(
    async (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      if (!data && !isLoading) {
        notify.info(
          'The document is being prepared for download. This may take a few seconds.'
        );
        setIsLoading(true);
        try {
          const response = await fetch(pathname, {
            headers: {
              Authorization: token
            }
          });
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          setData(url);
          window.open(url, '_blank'); // open the document in a new tab or window
        } catch (error) {
          console.error('Failed to fetch document', error);
          notify.error('Failed to fetch document');
        } finally {
          setIsLoading(false);
        }
      } else if (data) {
        window.open(data, '_blank');
      }
    },
    [data, isLoading, pathname, token]
  );
};
