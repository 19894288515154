import { Alert, Unstable_Grid2 as Grid, styled } from '@mui/material';
import {
  GetMfaMethodsResDto,
  useGetSessionInfo,
  usePostMfaSend,
  usePostMfaVerify
} from '@sentinel/hooks';
import { useAuth } from '@vestwell-frontend/elements';
import {
  Form,
  FormatEmail,
  FormField,
  Link,
  Text
} from '@vestwell-frontend/ui';

import { FC, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';
import { useLocalStorage } from 'usehooks-ts';
import * as yup from 'yup';

import { routes } from '../router/routes';
import { ResendOtpButton } from './ResendOtpButton';
import { SubmitButton } from './SubmitButton';

const StyledFormField = styled(FormField)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  width: theme.spacing(50)
}));

const StyledResendOtpButton = styled(ResendOtpButton)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(4)
}));

const StyledAlert = styled(Alert)({
  width: 'fit-content'
});

export type EmailVerificationProps = {
  email: Partial<GetMfaMethodsResDto>;
};

const schema = yup.object().shape({
  emailVerification: yup
    .string()
    .min(6, 'Must be at least 6 digits')
    .required('Required')
});

export const EmailVerification: FC<EmailVerificationProps> = memo(props => {
  const { setToken } = useAuth();

  const navigate = useNavigate();

  const user = useGetSessionInfo('user');

  const postMfaSend = usePostMfaSend();
  const postMfaVerify = usePostMfaVerify({
    mutation: {
      onSuccess: data => {
        //@ts-expect-error
        setToken(data);
      }
    }
  });

  const [, setCodeLastSentTime] = useLocalStorage('codeLastSentTime', {});

  const onSubmit = useCallback(
    async values => {
      await postMfaVerify.mutateAsync({
        data: {
          code: values.emailVerification,
          mfaEntryId: props.email.mfaEntryId,
          type: 'email'
        }
      });
    },
    [props.email.mfaEntryId]
  );

  const onResendCode = useCallback(async () => {
    const res = await postMfaSend.mutateAsync({
      data: { mfaEntryId: props.email.mfaEntryId, type: 'email' }
    });
    setCodeLastSentTime(prevState => ({
      ...prevState,
      [props.email.mfaEntryId]: Date.now()
    }));
    console.log(res.code);
  }, [props.email.mfaEntryId]);

  const isLoginDisabled = postMfaVerify.error?.message?.startsWith(
    'Your account has been temporarily locked'
  );

  useUpdateEffect(() => {
    if (postMfaVerify.data) {
      navigate(routes.PERSONAS);
    }
  }, [postMfaVerify.data]);

  return (
    <>
      <Grid>
        <Text
          align='center'
          data-testid='emailVerificationTitle'
          variant='b2'
          width='100%'>
          Verify Your Email
        </Text>
      </Grid>
      <Form
        className='contents'
        onChange={postMfaVerify.reset}
        onSubmit={onSubmit}
        validationSchema={schema}>
        <Grid
          alignItems='center'
          container
          display='flex'
          flexDirection='column'
          flexGrow={1}
          justifyContent='space-between'>
          <Grid xs={12}>
            <Text align='center' color='grey100' variant='i1'>
              Enter the 6-digit code we have emailed to{' '}
              <FormatEmail value={props.email.value} />
            </Text>
          </Grid>

          <Grid
            alignItems='center'
            display='flex'
            flexDirection='column'
            flexGrow={1}
            justifyContent='center'
            xs={12}>
            <StyledFormField
              align='center'
              autoComplete='one-time-code'
              autoFocus
              disabled={postMfaSend.isLoading || isLoginDisabled}
              format='numericString'
              label='Security Code'
              maxLength={6}
              name='emailVerification'
              type='tel'
              variant='large'
            />
            <StyledResendOtpButton
              disabled={isLoginDisabled}
              inputName='emailVerification'
              mfaEntryId={props.email.mfaEntryId}
              onClick={onResendCode}
              variant='inline'
            />
          </Grid>
          {postMfaVerify.error && (
            <StyledAlert severity='error'>
              {postMfaVerify.error.message}
            </StyledAlert>
          )}
          <Alert role='note' severity='warning'>
            Email-based two-factor authentication will soon be discontinued.
            Please set up an alternative phone or app-based method. To learn
            more, click{' '}
            <Link
              to={
                [18634, 26544].includes(user.data?.userId)
                  ? 'https://support.vestwell.com/hc/en-us/articles/13748426575639-The-Many-Benefits-of-Two-Factor-Authentication'
                  : 'https://support.vestwell.com/hc/en-us/articles/13748409298711-The-Many-Benefits-of-Two-Factor-Authentication'
              }>
              here
            </Link>
          </Alert>
          <SubmitButton disabled={isLoginDisabled}>Continue</SubmitButton>
        </Grid>
      </Form>
    </>
  );
});

EmailVerification.displayName = 'EmailVerification';
