import { nanoid } from 'nanoid';
import {
  createContext,
  FC,
  ReactNode,
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useRef
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePrevious, useUpdateEffect } from 'react-use';
import { createStore, StoreApi, useStore } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export type WizardStatus = 'active' | 'inactive' | 'complete' | 'error';

export type WizardConfig<T = Record<string, unknown>> = {
  initialState: T;
  steps: WizardStep<T>[];
};

export type WizardStep<State = Record<string, unknown>> = {
  caption?: ReactNode;
  /** set the component you want to render here */
  Component?: FC<{ state?: State } & Record<string, unknown>>;
  id?: number | string;
  index?: number;
  onClick?: () => void;
  /** status of step */
  status?: WizardStatus;
  subTitle?: ReactNode;
  /** title of step */
  title?: string;
  /** callback to set the step as active when it returns true */
  when?: (state: State) => boolean;
  url?: string;
};

export interface WizardProviderCtx<State = Record<string, unknown>> {
  /** whether the steps are link based  */
  hasLinks: boolean;
  /** whether there is a next step */
  hasNextStep: boolean;
  /** whether there is a prev step */
  hasPrevStep: boolean;
  /** current step index */
  index: number;
  /** initial state of the state */
  initialState?: State;
  /** navigate to next step */
  nextStep: (e?: SyntheticEvent) => void;
  /** navigate to prev step */
  prevStep: (e?: SyntheticEvent) => void;
  /** reset state to initialState */
  resetState: () => void;
  /** update current state */
  setState: (state: Partial<State>) => void;
  /** update current step */
  setStep: (step: WizardStep) => void;
  /** update steps */
  setSteps: (steps: WizardStep[]) => void;
  /** tracks the current state for the wizard */
  state?: State;
  /** current step in wizard */
  step?: WizardStep;
  /** all steps for wizard */
  steps: WizardStep[];
}

export interface WizardProviderProps<State = Record<string, unknown>> {
  children: ReactNode;
  /** initial state for the context */
  initialState?: State;
  /** namespace to use for localstorage */
  name?: string;
  /** callback for when state changes */
  onStateChange?: (state) => Promise<void> | void;
  /** callback for when step changes */
  onStepChange?: (step: WizardStep) => Promise<void> | void;
  /** current state; will use this value when change is detected */
  state?: State;
  /** the steps for the wizard */
  steps: WizardStep<State>[];
  /** enables localstorage of store */
  useLocalStorage?: boolean;
}

export const WizardContext = createContext<StoreApi<WizardProviderCtx>>(null);

export function useWizard<State = Record<string, unknown>>() {
  const store = useContext(WizardContext);
  return useStore(store) as WizardProviderCtx<State>;
}

export function storage<T>(useLocalStorage, store, name) {
  return useLocalStorage
    ? createStore<WizardProviderCtx<T>, any>(
        persist<WizardProviderCtx<T>>(set => store(set), {
          merge: (storage: WizardProviderCtx<T>, current) => {
            /**
             * load prior values stored, and re-hydrate the
             * steps with properties that could not be serialized,
             * e.g. when()
             */

            const initSteps = current.steps.reduce(
              (acc, step, i) => ({ ...acc, [i]: step }),
              {}
            );

            const steps = storage.steps.map((step, i) => ({
              ...step,
              ...initSteps[i]
            }));

            return {
              ...current,
              ...storage,
              steps
            };
          },
          name,
          storage: createJSONStorage(() => sessionStorage)
        })
      )
    : createStore<WizardProviderCtx<T>>(set => store(set));
}

export function WizardProvider<State extends Record<string, unknown>>({
  useLocalStorage,
  name,
  ...props
}: WizardProviderProps<State>) {
  const location = useLocation();
  const isStorybook = !!process.env.STORYBOOK;

  const steps = useMemo(
    () =>
      props.steps.map((step, index) => ({
        ...step,
        id: step.id || nanoid(),
        index,
        title: step.title
      })),
    [props.steps]
  );

  const hasLinks = useMemo(() => steps.every(step => step.url), [steps]);

  const index = useMemo(() => {
    if (!hasLinks) return 0;
    const foundIndex = steps.findIndex(({ url }) =>
      location.pathname.endsWith(url)
    );
    return foundIndex !== -1 || !isStorybook ? foundIndex : 0;
  }, [hasLinks, steps, location.pathname, isStorybook]);

  const createWizardStore = () =>
    storage<State>(
      useLocalStorage,
      set => ({
        hasLinks,
        hasNextStep: !!steps[index + 1],
        hasPrevStep: !!steps[index - 1],
        index,
        initialState: props.initialState,
        nextStep: () =>
          set(prev => {
            const index = prev.hasLinks
              ? prev.steps.findIndex(({ url }) =>
                  window.location.pathname.endsWith(url)
                ) + 1
              : prev.index + 1;

            return {
              ...prev,
              hasNextStep: !!prev.steps[index + 1],
              hasPrevStep: true,
              index,
              step: prev.steps[index]
            };
          }),
        prevStep: () =>
          set(prev => {
            const index = prev.hasLinks
              ? prev.steps.findIndex(({ url }) =>
                  window.location.pathname.endsWith(url)
                ) - 1
              : prev.index - 1;

            return {
              ...prev,
              hasNextStep: true,
              hasPrevStep: !!prev.steps[index - 1],
              index,
              step: prev.steps[index]
            };
          }),
        resetState: () =>
          set(prev => ({
            ...prev,
            state: prev.initialState
          })),
        setState: update =>
          set(prev => {
            return {
              ...prev,
              state: {
                ...prev.state,
                ...update
              }
            };
          }),
        setStep: (step: WizardStep<State>) =>
          set(prev => ({
            ...prev,
            hasNextStep: !!prev.steps[step.index + 1],
            hasPrevStep: !!prev.steps[step.index - 1],
            index: step.index,
            step
          })),
        setSteps: (steps: WizardStep<State>[]) =>
          set(prev => {
            const updatedSteps = steps.map((step, index) => ({
              ...step,
              id: step.id || nanoid(),
              index
            }));

            const hasLinks = updatedSteps.every(step => step.url);

            const index = hasLinks
              ? updatedSteps.findIndex(({ url }) =>
                  location.pathname.endsWith(url)
                )
              : 0;

            return {
              ...prev,
              hasLinks,
              hasNextStep: !!prev.steps[index + 1],
              hasPrevStep: !!prev.steps[index - 1],
              index,
              step: prev.steps[index],
              steps: updatedSteps
            };
          }),
        state: props.state ?? props.initialState,
        step: steps[index],
        steps
      }),
      name
    );

  const storeRef = useRef<ReturnType<typeof createWizardStore>>(undefined);

  if (!storeRef.current) {
    storeRef.current = createWizardStore();
  }

  return (
    <WizardContext.Provider value={storeRef.current}>
      <WizardProviderCtrl
        initialState={props.initialState}
        onStateChange={props.onStateChange}
        onStepChange={props.onStepChange}
        steps={steps}>
        {props.children}
      </WizardProviderCtrl>
    </WizardContext.Provider>
  );
}

export const WizardProviderCtrl: FC<
  Pick<
    WizardProviderProps,
    'children' | 'initialState' | 'onStateChange' | 'onStepChange' | 'steps'
  >
> = props => {
  const location = useLocation();
  const navigate = useNavigate();

  const store = useWizard();
  const prevIndex = usePrevious(store.step?.index ?? 0);

  /** update the steps and initialState when reference changes */
  useUpdateEffect(() => {
    if (props.steps?.length) {
      store.setState(props.initialState);
      store.setSteps(props.steps);
    }
  }, [props.steps]);

  /** update the step when state changes & at least one step has when property */
  useEffect(() => {
    /** using state driven */
    if (!store.hasLinks && prevIndex > -1 && prevIndex > store.step.index) {
      return;
    }

    if (!store.steps.some(step => step.when)) {
      return;
    }

    const nextStep = store.steps
      .slice()
      .reverse()
      .find(step => (step.when ? step.when(store.state) : step));

    const hasStepChanged = store.hasLinks
      ? nextStep && store.step?.url !== nextStep?.url
      : nextStep && store.step.index !== nextStep?.index;

    if (hasStepChanged) {
      store.setStep(nextStep);
    }

    if (store.hasLinks && nextStep && nextStep.url !== location.pathname) {
      navigate(nextStep.url);
    }
  }, [store.state, prevIndex, location.pathname]);

  /** update the step when url changes */
  useUpdateEffect(() => {
    if (store.hasLinks) {
      const nextStep = store.steps.find(({ url }) =>
        location.pathname.endsWith(url)
      );

      if (nextStep && store.step?.url !== nextStep?.url) {
        store.setStep(nextStep);
      }
    }
  }, [location.pathname, store.steps]);

  /** when step changes, navigate to that url */
  useUpdateEffect(() => {
    if (store.step?.url) {
      navigate(store.step.url);
    }
  }, [store.step?.url]);

  /** when state changes, call onStateChange */
  useUpdateEffect(() => {
    (async () => {
      if (props.onStateChange) {
        await props.onStateChange(store.state);
      }
    })();
  }, [store.state]);

  /** when step changes, call onStepChange */
  useEffect(() => {
    (async () => {
      if (props.onStepChange) {
        await props.onStepChange(store.step);
      }
    })();
  }, [store.step]);

  /**
   * prevent showing children when step is undefined;
   * e.g. wait for step to be defined
   */
  return <>{!!store.step && props.children}</>;
};
