import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import { Unstable_Grid2 as Grid, Stack, styled } from '@mui/material';
import { useDocumentTitle } from '@vestwell-frontend/hooks';
import {
  Button,
  Form,
  FormRadioGroup,
  FormSaveButton,
  Link,
  ModalBody,
  ModalFooter,
  RadioButton,
  Text,
  useWizard
} from '@vestwell-frontend/ui';

import { FC, useCallback } from 'react';
import * as yup from 'yup';

import { MfaNudgeState } from '../config/mfaNudge';

const schema = yup.object().shape({
  mfaMethod: yup.string().required('Required')
});

const StyledRadioButton = styled(RadioButton)(({ theme }) => ({
  paddingBottom: theme.spacing(4),
  paddingTop: theme.spacing(4)
}));

export const MfaNudgeMethodPage: FC = () => {
  const ctx = useWizard<MfaNudgeState>();

  useDocumentTitle(ctx.step.title as string);

  const onSubmit = useCallback(async data => {
    ctx.setState({
      isMfaMethodSelected: true,
      mfaMethod: data.mfaMethod
    });
  }, []);

  const onBack = useCallback(() => {
    ctx.setState({
      isUserReadyToEnableMfa: false
    });
  }, []);

  return (
    <Form
      className='contents'
      initialValues={{ mfaMethod: 'app' }}
      onSubmit={onSubmit}
      validateOnMount
      validationSchema={schema}>
      <ModalBody>
        <Grid
          alignItems='center'
          columns={12}
          container
          direction='column'
          spacing={4}>
          <Grid
            alignItems='center'
            display='flex'
            flexDirection='column'
            lg={12}>
            <Text
              align='center'
              color='oxfordBlue'
              component='span'
              mb={2}
              variant='f2'>
              Two-Factor Authentication
            </Text>
            <Text align='center' color='oxfordBlue' variant='b2'>
              {ctx.step.title}
            </Text>
          </Grid>
          <Grid lg={10} xs={12}>
            <Text align='center' color='black' mb={4}>
              As an added layer of security, you must enter a security code
              after entering your password. Select your preferred method to
              receive security codes.
            </Text>
          </Grid>
          <Grid lg={10} xs={12}>
            <FormRadioGroup
              fullWidth
              hideLabel
              label='Mfa Method'
              name='mfaMethod'
              variant='card'>
              <Stack spacing={4}>
                <StyledRadioButton
                  label={
                    <div>
                      <Text color='grey300' mb={0} variant='k3'>
                        Recommended
                      </Text>
                      <Text color='grey50' component='h2' mb={0} variant='f2'>
                        Authentication App
                      </Text>
                      <Text color='grey300' mb={0} variant='i1'>
                        (ex.{' '}
                        <Link
                          data-testid='googleAuthenticatorLink'
                          external
                          to='https://support.google.com/accounts/answer/1066447'>
                          Google Authenticator
                        </Link>
                        ,&nbsp;
                        <Link
                          data-testid='googleAuthenticatorLink'
                          external
                          to='https://support.microsoft.com/en-au/account-billing/download-and-install-the-microsoft-authenticator-app-351498fc-850a-45da-b7b6-27e523b8702a'>
                          Microsoft Authenticator
                        </Link>
                        )
                      </Text>
                    </div>
                  }
                  value='app'
                />

                <StyledRadioButton
                  label={
                    <div>
                      <Text color='grey50' component='h2' mb={0} variant='f2'>
                        Phone Number
                      </Text>
                      <Text
                        color='grey300'
                        display='block'
                        mb={0}
                        mt={2}
                        variant='i1'>
                        Verify your identity via a text message or voice call.
                      </Text>
                      <Text color='grey300' display='block' mb={0} variant='i1'>
                        Standard rates may apply.
                      </Text>
                    </div>
                  }
                  value='phone'
                />
              </Stack>
            </FormRadioGroup>
          </Grid>
        </Grid>
      </ModalBody>
      <ModalFooter justifyBetween noBoxShadow>
        <Button onClick={onBack} variant='text'>
          Back
        </Button>
        <FormSaveButton
          data-testid='nextSetup'
          endIcon={<KeyboardArrowRightOutlined fontSize='small' />}>
          Next: Set up
        </FormSaveButton>
      </ModalFooter>
    </Form>
  );
};

MfaNudgeMethodPage.displayName = 'MfaNudgeMethodPage';
